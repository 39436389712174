/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function ($, _c, undefined) {
    'use strict';

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.

    var Sage = {

        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                //Mobile Login
                $(".login-btn").on("click", function(){
                    $(this).toggleClass("show-login");
                });

                $(".mobile-login").on("click", function(e){
                    e.stopPropagation();
                    $(this).toggleClass("show-login");
                });

                $(".mobile-login").siblings("div").on("click", function(e){
                    e.stopPropagation();
                });

                $(document).on("click", function(e){
                    $(".mobile-login").removeClass("show-login");
                });

                $('.scrollto').on('click', function (event) {
                    event.preventDefault();
                    var section = $(this).attr('href');
                    $('html, body').animate({
                        scrollTop: $(section).offset().top
                    }, 1000);
                });

				var timer;
				var $header = $('header');

				if (!$header.hasClass('disable-fixed')) {
					$(window).on('scroll', function () {
						clearTimeout(timer);
						var scroll_top = $(window).scrollTop();
						if (scroll_top > 90) {
							$header.addClass('fixed');
						}
						if (scroll_top === 0) {
							$header.removeClass('fixed');
						}
						$(".mobile-login").removeClass("show-login");
					});

					$(window).on('load', function(){
						var scroll_top = $(window).scrollTop();
						if (scroll_top > 90) {
							$header.addClass('fixed');
						}
					});
				}

                var didScroll;
                var lastScrollTop = 0;
                var delta = 5;
                var navbarHeight = $header.outerHeight();

                $(window).scroll(function (event) {
                    didScroll = true;
                });

                setInterval(function () {
                    if (didScroll) {
                        hasScrolled();
                        didScroll = false;
                    }
                }, 250);

                function hasScrolled() {
                    var st = $(window).scrollTop();
                    if (Math.abs(lastScrollTop - st) <= delta)
                        return;
                    if (st > lastScrollTop && st > navbarHeight) {
                        $header.removeClass('nav-down').addClass('nav-up');
                    } else {
                        if (st + $(window).height() < $(document).height()) {
                            $header.removeClass('nav-up').addClass('nav-down');
                        }
                    }
                    lastScrollTop = st;
                }

                if ($('.reveal').length > 0) {
                    $('.reveal').each(function (e) {
                        var reveal = $(this);
                        var waypoint_reveal = new Waypoint({
                            element: reveal[0],
                            handler: function (direction) {
                                if (direction === 'down') {
                                    reveal.addClass('on');
                                }
                            },
                            offset: reveal.data("offset") || '77%'
                        });
                    });
                }

                $('.hamburger').on('click', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    if ($(this).hasClass('is-active')) {
                        $('.nav').addClass('active');
                        $('.logo').addClass('blue');
                        $('.mobile-login').addClass('blue');
                        $('.mobile-login').siblings().addClass('blue');
                    } else {
                        $('.nav').removeClass('active');
                        $('.logo').removeClass('blue');
                        $('.mobile-login').removeClass('blue');
                        $('.mobile-login').siblings().removeClass('blue');
                    }
                });
                $('.nav').on('click', function (event) {
                    event.stopPropagation();
                });

                if ($('.people-slider').length > 0) {
                    var people_swiper = new Swiper('.people-slider .swiper-container', {
                        autoplay: false,
                        slidesPerView: 1,
                        loop: true,
                        speed: 1200,
                        autoHeight: true,
                        spaceBetween: 0,
                        simulateTouch: true,
                        queueStartCallbacks: true,
                        queueEndCallbacks: true,
                        pagination: {
                            el: '.pagination',
                            type: 'bullets',
                            clickable: true,
                        },
                        breakpoints: {
                            1023: {},
                            768: {},
                        },
                    });
                    $('.people-slider .next').on('click', function (event) {
                        event.preventDefault();
                        people_swiper.slideNext();
                    });
                    $('.people-slider .prev').on('click', function (event) {
                        event.preventDefault();
                        people_swiper.slidePrev();
                    });
                }

                $('.faq-item a').on('click', function (event) {
                    event.preventDefault();
                    if ($(this).hasClass('open')) {
                        $(this).removeClass('open');
                        $(this).next().slideUp(700);
                    } else {
                        $(this).addClass('open');
                        $(this).next().slideDown(700);
                    }
                });

                $(window).on('load', function () {
                    if ($('.landing-banner').length > 0) {
                        $('.landing-banner .stars div').addClass('on');
                    }
                });

                if ($('.stars').length > 0) {
                    $('.stars div').each(function (e) {
                        var stars = $(this);
                        var waypoint_stars = new Waypoint({
                            element: stars[0],
                            handler: function (direction) {
                                if (direction === 'down') {
                                    stars.addClass('on');
                                }
                            },
                            offset: '50%'
                        });
                    });
                }

                $(window).on('scroll', function () {
                    var scrolled = $(window).scrollTop();
                    $('.photo-section .photo, .photo-section img').each(function () {

                        var photo_offset = $(this).offset().top;
                        var scrollme = scrolled - photo_offset;
                        $(this).css('transform', 'translate3d(0, ' + scrollme * -0.2 + 'px, 0)');
                    });
                    $('.device-section .photo').each(function () {
                        var device_offset = $(this).offset().top;
                        var scrollme = scrolled - device_offset;
                        $(this).css('transform', 'translate3d(0, ' + scrollme * -0.2 + 'px, 0)');
                    });
                });

                // if ($('.photo-section').length > 0){
                //        $('.photo-section .photo').each(function(e){
                //            var photo = $(this);
                //            var waypoint_photo = new Waypoint({
                //                element: photo[0],
                //                handler: function(direction) {
                //                    if (direction === 'down'){
                //                        photo.addClass('on');
                //                    }
                //                },
                //                offset: '50%'
                //            });
                //        });
                //    }

                // if ($('.device-section').length > 0){
                //        $('.device-section .photo').each(function(e){
                //            var device = $(this);
                //            var waypoint_device = new Waypoint({
                //                element: device[0],
                //                handler: function(direction) {
                //                    if (direction === 'down'){
                //                        device.addClass('on');
                //                    }
                //                },
                //                offset: '50%'
                //            });
                //        });
                //    }

                function initialize() {
                    var latlng = new google.maps.LatLng(37.5772795, -122.3482391);
                    var styles = [{
                        "featureType": "administrative",
                        "elementType": "all",
                        "stylers": [{"saturation": "-100"}]
                    }, {
                        "featureType": "administrative.province",
                        "elementType": "all",
                        "stylers": [{"visibility": "off"}]
                    }, {
                        "featureType": "landscape",
                        "elementType": "all",
                        "stylers": [{"saturation": -100}, {"lightness": 65}, {"visibility": "on"}]
                    }, {
                        "featureType": "poi",
                        "elementType": "all",
                        "stylers": [{"saturation": -100}, {"lightness": "50"}, {"visibility": "simplified"}]
                    }, {
                        "featureType": "road",
                        "elementType": "all",
                        "stylers": [{"saturation": "-100"}]
                    }, {
                        "featureType": "road.highway",
                        "elementType": "all",
                        "stylers": [{"visibility": "simplified"}]
                    }, {
                        "featureType": "road.arterial",
                        "elementType": "all",
                        "stylers": [{"lightness": "30"}]
                    }, {
                        "featureType": "road.local",
                        "elementType": "all",
                        "stylers": [{"lightness": "40"}]
                    }, {
                        "featureType": "transit",
                        "elementType": "all",
                        "stylers": [{"saturation": -100}, {"visibility": "simplified"}]
                    }, {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [{"hue": "#ffff00"}, {"lightness": -25}, {"saturation": -97}]
                    }, {
                        "featureType": "water",
                        "elementType": "labels",
                        "stylers": [{"lightness": -25}, {"saturation": -100}]
                    }];
                    var styledMap = new google.maps.StyledMapType(styles);

                    var myOptions =
                        {
                            center: new google.maps.LatLng(37.5772795, -122.3482391),
                            zoom: 15,
                            mapTypeControlOptions: {
                                mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
                            },
                            panControl: false,
                            zoomControl: false,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            overviewMapControl: false
                        };

                    var map = new google.maps.Map(document.getElementById("map"), myOptions);

                    var myMarker = new google.maps.Marker(
                        {
                            position: latlng,
                            icon: 'http://frontend.lyra.sandbox5.cliquedomains.com/images/pin.png',
                            map: map,
                        });

                    map.mapTypes.set('map_style', styledMap);
                    map.setMapTypeId('map_style');
                    myMarker.setMap(map);
                }

                if ($('#map').length > 0) {
                    initialize();
                }

                if ($('.info-graphic-section').length > 0) {
                    $('.info-graphic-section img').each(function (e) {
                        var image = $(this);
                        var waypoint_image = new Waypoint({
                            element: image[0],
                            handler: function (direction) {
                                if (direction === 'down') {
                                    image.addClass('on');
                                }
                            },
                            offset: '87%'
                        });
                    });
                }

                if ($('.info-graphic-section').length > 0) {
                    $('.info-graphic-section .cnt').each(function (e) {
                        var image = $(this);
                        var waypoint_image = new Waypoint({
                            element: image[0],
                            handler: function (direction) {
                                if (direction === 'down') {
                                    image.addClass('on');
                                }
                            },
                            offset: '87%'
                        });
                    });
                }

                if ($('.filter-bar').length > 0) {
                    $('.filter-bar .dd-link').on('click', function (event) {
                        event.preventDefault();
                        if ($(this).parent().hasClass('active')) {
                            $(this).next().slideUp(300);
                            $(this).parent().removeClass('active');
                            $('.page-overlay').fadeOut(300);
                        } else {
                            $(this).next().slideDown(300);
                            $(this).parent().addClass('active');
                            $('.page-overlay').fadeIn(300);
                        }
                    });
                }

                $('.filter-bar input[type="text"]').on('focus', function () {
                    $(this).parent().addClass('active');
                });
                $('.filter-bar input[type="text"]').on('blur', function () {
                    $(this).parent().removeClass('active');
                });

                // $(window).on('scroll', function(){
                // 	var scrolled = $(window).scrollTop();
                // 	$('.photo').each(function(){
                // 		var photo_offset = $(this).offset().top;
                // 		var scrollme = scrolled - photo_offset;
                // 		$(this).css('transform', 'translate3d(0, '+ scrollme * -0.2 +'px, 0)');
                // 	});
                // });

                $('.btn').click(function (e) {
                    var page = $(this).data('page');
                    var action = $(this).data('action');

                    if (typeof page != 'undefined' && page !== '' && typeof action != 'undefined' && action !== '') {
                        mixpanel.track("Button Press", {
                            'page': page,
                            'action': action
                        });
                    }
                })

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
                //Pardot Form
                if($("#pardot-iframe").length > 0){
                    $("#pardot-iframe").iFrameResize({checkOrigin: false});
                }
                //console.log("test");
            }
        },
        'page_template_template_blog': {
            init: function () {
                $('.filter-topic ul.dd li a').click(function(e) {
                    e.preventDefault();
                    $('input[name=topic]').val($(this).data('topic'));
                    $('input[name=page]').val(1);
                    $('#blog-form').submit();
                })
                $('.filter-audience ul.dd li a').click(function(e) {
                    e.preventDefault();
                    $('input[name=audience]').val($(this).data('audience'));
                    $('input[name=page]').val(1);
                    $('#blog-form').submit();
                })
            },
            finalize: function () {
            }
        },
        'page_template_template_resources': {
            init: function () {

                $('.filter-cat ul.dd li a').click(function(e) {
                    e.preventDefault();
                    $('input[name=cat]').val($(this).data('cat'));
                    $('input[name=page]').val(1);
                    $('#resource-form').submit();
                });

                $('.swiper-container').each(function (i){
                    var slider_id = $(this).attr('id');
                    var resources_swiper = new Swiper('#'+slider_id, {
                        autoplay: false,
                        slidesPerView: 3,
                        loop: true,
                        speed: 1200,
                        autoHeight: true,
                        spaceBetween: 20,
                        simulateTouch: true,
                        queueStartCallbacks: true,
                        queueEndCallbacks: true,
                        breakpoints: {
                            1024: {
                                slidesPerView: 2,
                            },
                            1023: {
                                slidesPerView: 3,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            480: {
                                slidesPerView: 1,
                            },
                        },
                    });
                    $('.next').on('click', function (event) {
                        event.preventDefault();
                        resources_swiper.slideNext();
                    });
                });

                $('.pardot-more').on('click', function (event){
                    event.preventDefault();
                    var id = $(this).data('pardot');
                    $('.pardot-'+id).fadeIn(400);
				});

                $('.pardot-lightbox .close').on('click', function (event){
                    event.preventDefault();
                    $('.pardot-lightbox').fadeOut(400);
				});

				$('.news-section .load-more-button').on('click', function() {
					$(this).closest('.load-more-row').addClass('hide');
					$(this).closest('.news-section').find('.more.row').addClass('open');
				});

            },
            finalize: function () {
            }
        },
        // Try Coaching page
        'page_template_template_coaching': {
            init: function() {
                var carouselSelector = '.carousel';
                var carouselContentSelector = '.content';
                var carouselControlsSelector = '.controls';

                // Sets up scrolling listener to achieve sticky header & autoplay videos on mobile
                window.onscroll = function () {
                    var stuckClass = 'stuck';
                    var stickyHeaderClass = '.sticky.header';
                    var stickingPoint = $(stickyHeaderClass).offset().top;

                    if (window.pageYOffset > stickingPoint) {
                        $(stickyHeaderClass).addClass(stuckClass);
                    } else {
                        $(stickyHeaderClass).removeClass(stuckClass);
                    }

                    // play videos if they're in view
                    if (window.innerWidth < 1023) {
                        $('video').each(function(index, video) {
                            if (video.currentTime === 0 && (window.pageYOffset + $(window).height()) > $(video).offset().top) {
                                video.play();
                            }
                        });
                    }
                };


                // Helper function to handle displaying items in carousel
                var scrollToItem = function (itemNumber, carousel) {
                    if (itemNumber < 0) {
                        return;
                    }

                    var carouselElement = $(carousel);

                    var carouselContent = carouselElement.find(carouselContentSelector);
                    var carouselControls = carouselElement.find(carouselControlsSelector);

                    // (1) Scroll `carouselElement` to correct position
                    carouselContent.scrollLeft($(carouselElement).width() * itemNumber);

                    // (2) Update .controls>li with .selected class
                    carouselControls.find('li').removeClass('selected');
                    $(carouselControls.find('li')[itemNumber]).addClass('selected');
                    carouselContent.children().removeClass('selected');
                    $(carouselContent.children()[itemNumber]).addClass('selected');

                    // (3) Play any un-played video inside a selected element
                    var video = $(carouselContent).find('.selected video').get(0);
                    if (video && video.currentTime === 0) {
                        video.play();
                    }
                };

                // Foreach carousel on the page
                $(carouselSelector).each(function (carouselIndex, carouselElement) {
                    var carouselControls = $(carouselElement).find(carouselControlsSelector);

                    // Add a resize event to re-calculate carousel
                    $(window).on('resize', function () {
                        scrollToItem(
                            carouselControls.find('li.selected').index(),
                            carouselElement
                        );
                    });


                    // Also start videos if the carousel is hidden
                    // if (window.innerWidth < 1023) {
                    //     console.log('playing all videos!');
                    //     $('video').each(function(index, video) {
                    //         // video.play();
                    //         video.autoplay = true;
                    //     });
                    //     // console.log($(carouselElement).find('video'));
                    //     // $(carouselElement).find('video').attr('autoplay', '');
                    // }

                    // Foreach control-group in that carousel
                    carouselControls.each(function (controlsIndex, controlsElement) {
                        var carouselControls = $(controlsElement).find('li');

                        // Foreach control in that control-group, wire up a click event to handle switching
                        carouselControls.each(function (controlIndex, controlElement) {
                            $(controlElement).on('click', function() {
                                scrollToItem(controlIndex, carouselElement);
                            })
                        });

                        // If prev/next buttons exist, wire up a click event to handle switching
                        $(controlsElement).find('.prev').on('click', function () {
                            var currentIndex = carouselControls.index($('.selected'));
                            if (currentIndex - 1 >= 0) {
                                scrollToItem(currentIndex - 1, carouselElement);
                            }
                        });
                        $(controlsElement).find('.next').on('click', function () {
                            var currentIndex = carouselControls.index($('.selected'));
                            if (currentIndex + 1 < carouselControls.length) {
                                scrollToItem(currentIndex + 1, carouselElement);
                            }
                        });
                    });
                });
            },
            finalize: function() {
            }
        }

    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = funcname === undefined ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
})(window.jQuery, window.Clique); // Fully reference jQuery after this point.
